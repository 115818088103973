
.convocation-component{
  position: relative; 
}

.convocation-component::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('./Convo Media/background.jpg'); */
    background: rgb(246, 244, 244);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    opacity: 0.7; /* Adjust this for more/less transparency */
    z-index: -1;
  }
  
.convocation-inner-container {
  width: 100%;
  margin: 0 auto;
}
.convo-7th-ul {
  line-height: 29px;
  width: 80%;
  margin: auto;
  display: flex
;
  /* list-style: none; */
}
.convo-7th-ul li {
 margin: 0 20px;
}
.convo-7th-ul li a {
  color: black;
  font-size: 1rem;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}
.convo-7th-ul li a:hover {
  color: rgb(255, 4, 4);
}

.convocation-backdrop-container-image {
  width: 100%;
}
.convocation-backdrop-container-image img {
  width: 100%;
}

.convocation-photo-video-gallery-section {
  background-color: rgb(232 238 255 / 62%);
  width: 100%;
  padding: 2% 0;
}

.convocation-photo-video-gallery-section {
  width: 100%;
  display: flex;
}
.convo-photo-gallery {
  width: 45%;
  margin: auto;
}
.convoation-nuca-crousel {
  width: 100%;
  
}
.convoation-ifram-video {
  width: 100%;
  height: 50vh;
 
}
.convoation-nuca-crousel img {
  width: 100%;
  height: 50vh;
}

.convocation-text-box-container {
  width: 100%;
  background-color: #ffc0c0;
}
.convo-text-box {
  width: 90%;
  padding: 2% 0;
  margin: auto;
  font-size: 1.2rem;
  line-height: 26px;
}
.convocation-photo-video-heading-text {
  background-color: #950505;
  color: white;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0;
  font-size: 1.2rem;
  padding: 5px 0;
}
.guest-of-convo-heading {
  text-align: center;
  font-weight: bold;
  margin: 4% auto;
}
.chief-guest-list-container-convocation {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.chief-guest-convo-box {
  width: 18%;
  background: rgba(177, 177, 177, 0.14);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(177, 177, 177, 0.28);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 2px 5px black;
}
.guest-heading-name-convo {
  font-weight: bold;
  text-align: center;
  margin: 2% auto;
  font-size: 1.5rem;
}
.name-and-designation-of-guest-convo {
  text-align: center;
  margin: 4% auto;
  font-size: 1.2rem;
}
.guest-convo-image {
  width: 80%;
  height: 209px;
  margin: 1% auto;
  border-radius: 10px;
}
.phd-receving-degree-container {
  width: 100%;
  background-color: #ffc0c09b;
}
.phd-degree-heading-convo {
    text-align: center;
    margin: 1% auto;
    font-size: 2.5rem;
}

.phd-degree-heading-convo-secondary{
    text-align: center;
color: red;
    margin:1.5% auto;
}
.phd-receving-degree-inner-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}
.Phd-student-degree-box {
  width: 30%;
  margin: 1.5% auto;
  background: #edededd6;
  padding: 10px 0;
  box-shadow: 2px 0px 16px 2px #2a2727;
  border: 1px solid black;
}
.phd-degree-image {
  width: 86%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.name-of-phd-degree-holder {
  text-align: center;
  font-size: 1.3rem;
  background-color: #e0354f;
  color: white;
  width: 86%;
  margin: auto;
  margin-bottom: 4%;
  padding: 4px 0;
}
.name-of-phd-degree-holder-gold-medelist {
  text-align: center;
  font-size: 1.3rem;
  background-color: #FFD700;
  color: rgb(0, 0, 0);
  width: 86%;
  margin: auto;
  margin-bottom: 4%;
  padding: 4px 0;
}

.gold-medelist-heading-convo {
    text-align: center;
    font-weight: bold;
    margin: 4px auto;
    background: gainsboro;
    padding: 7px 0;
}




/* Media query */
@media only screen and (max-width: 600px) {
    .convocation-photo-video-gallery-section {
        width: 100%;
        display: flex
    ;
        flex-direction: column;
    }
    .convo-photo-gallery {
        width: 80%;
        margin: auto;
    }
    .chief-guest-list-container-convocation {
        display: flex
    ;
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
    }
    .chief-guest-convo-box {
        width: 70%;
        margin:2% auto;
    }
    .phd-degree-heading-convo {
        text-align: center;
        width: 80%;
        font-size: 3rem;
        margin-top: 2%;
    }
    .phd-degree-heading-convo-secondary{
        text-align: center;
        width: 80%;
    }
    .Phd-student-degree-box {
        width: 46%;
        margin:3% auto;
    }
}

/* for desktop screen */


@media only screen and (min-width: 1300px) {
.chief-guest-list-container-convocation{
  width: 80%;
  margin: auto;
}
.guest-convo-image{
  height: 220px;
}
}