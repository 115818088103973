.office-order-container{
    width: 100%;
    padding-bottom: 5%;
}
.office-order-container ul{
   list-style-type: circle;
    line-height: 29px;
    width: 95%;
    margin: auto;
    padding-top: 2%;
}
.office-order-container ul li a{
    font-size: 1.2rem;
    color: black;
    text-decoration: none;
    transition: all .3s ease;
}
.office-order-container ul li a:hover{
   color: red;
}