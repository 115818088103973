.unnat-bharat-abhiyan-logo-upper{
width:90%;

}
.unnat-bharat-abhiyan-logo-upper img{
width:20%;
height: 200px;

}
.middle-logo-of-unnat-bharat{
    width: 30%;
}